.slider__bg {
  background-image: var(--bg-image-mobile);
}

@media screen and (min-width: 320px) {
  .slider__bg {
    background-image: var(--bg-image-phablet);
  }
}
@media screen and (min-width: 480px) {
  .slider__bg {
    background-image: var(--bg-image-tablet);
  }
}
@media screen and (min-width: 768px) {
  .slider__bg {
    background-image: var(--bg-image-desktop);
  }
}
@media screen and (min-width: 1024px) {
  .slider__bg {
    background-image: var(--bg-image-large);
  }
}
@media screen and (min-width: 1200px) {
  .slider__bg {
    background-image: var(--bg-image-hd);
  }
}